/* eslint-disable array-callback-return */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import io from "socket.io-client";
import mainLogo from '../img/cadMainLogo.png';
import '../css/secondNavbar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

const socket = io.connect("https://api.caddcornerbd.com");

const SecondNavbar = () => {

    let navigate = useNavigate();

    const [windowScroll, setWindowScroll] = useState(window.pageYOffset);

    const [courseCatagories, setCourseCatagories] = useState([]);
    const [courseNames, setCourseNames] = useState([]);

    const myNav = useRef(null);
    const profileNav = useRef(null);

    // fetch course catagories
    const fetchCourseCatagories = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetch/catagories",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourseCatagories(json.result);
    }

    // fetch course catagories
    const fetchCourseNames = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetchnames",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourseNames(json.result);
    }

    let location = useLocation();

    const windowScrollSize = () => {
        setWindowScroll(window.pageYOffset);
    }

    const openNav = () => {
        myNav.current.style.transform = "scaleX(1)";
    }

    const openProfileNav = () => {
        profileNav.current.style.transform = "scaleX(1)";
    }

    const closeNav = () => {
        myNav.current.style.transform = "scaleX(0)";
    }

    const closeProfileNav = () => {
        profileNav.current.style.transform = "scaleX(0)";
    }

    //handle logout
    const handleLogOut = () => {
        localStorage.removeItem("authToken");
        navigate('/');
    }

    window.addEventListener('scroll', windowScrollSize);



    useEffect(() => {
        fetchCourseCatagories();
        fetchCourseNames();
        socket.on("added_course", (data) => {
            fetchCourseNames();
        });
        socket.on("updated_course", (data) => {
            fetchCourseNames();
        });
        socket.on("deleted_course", (data) => {
            fetchCourseNames();
        });
        socket.on("added_catagory", (data) => {
            fetchCourseCatagories();
        });
        socket.on("updated_catagory", (data) => {
            fetchCourseCatagories();
        });
        socket.on("deleted_catagory", (data) => {
            fetchCourseCatagories();
        });
    }, [])
    return (
        // <div className='secondNavbar'>
        <div className={`secondNavbar ${windowScroll > 48 ? ('fixed-top') : ('')}`}>
            <div className='container d-none d-lg-block'>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to='/'> <img src={mainLogo} alt='CADD CORNER BD' className='img-fluid headerLogo' /> </Link>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' ? ('activeLink') : ('')}`} aria-current="page" to='/'>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/stdntschlrship' ? ('activeLink') : ('')}`} to='/stdntschlrship'>Student Scholarship</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" href="javascript:void(0);" id="secondNavbarDrop" data-toggle="dropdown">Student's Corner</a>
                                <div className='dropdown-menu'>
                                    <Link className={`dropdown-item fancyLinks ${location.pathname === '/requestforcertificate' ? ('activeLink') : ('')}`} to='/requestforcertificate'><span className="linkTHEff">Request for Certificate</span></Link>
                                    <Link className={`dropdown-item fancyLinks ${location.pathname === '/verifycertificate' ? ('activeLink') : ('')}`} to='/verifycertificate'><span className="linkTHEff">Verify Certificate</span></Link>
                                    <a className="dropdown-item fancyLinks" href="l"><span className="linkTHEff">Request For Services</span></a>
                                    <a className="dropdown-item fancyLinks" href="l"><span className="linkTHEff">Complaint</span></a>
                                    <a className="dropdown-item fancyLinks" href="l"><span className="linkTHEff">Civil Box</span></a>
                                </div>
                            </li>
                            <li className="nav-item dropdown has-megamenu">
                                <a className={`nav-link ${location.pathname.substring(1, 7) === `course` ? ('activeLink') : ('')}`} href="javascript:void(0);" id="navbardrop" data-toggle="dropdown">
                                    Courses
                                </a>
                                <div className='dropdown-menu megamenu' style={{ backgroundColor: "#eee" }}>
                                    <Accordion>
                                        <div className='row' style={{ padding: "5px 10px" }}>
                                            {courseCatagories.map((catagory, el) => {
                                                return <div className='col-lg-3' key={el}>
                                                    <Accordion.Item eventKey={el} style={{ background: "transparent", border: "1px solid #fff", borderRadius: "0px", margin: "10px 0px" }}>
                                                        <Accordion.Header style={{ border: "1px solid #000" }}>{catagory.catagory}</Accordion.Header>
                                                        <Accordion.Body style={{ border: "1px solid #000" }}>
                                                            {courseNames.map((course) => {
                                                                while (course.catagory === catagory.catagory) {
                                                                    return <div key={course.id}>
                                                                        <Link className={`nav-link ${location.pathname.substring(8) === course.id ? ('activeLink') : ('')}`} to={`/course/${course.id}`} style={{ padding: "5px", border: "1px solid", margin: "5px 0" }}>{course.name}</Link>
                                                                    </div>
                                                                }
                                                            })}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </div>
                                            })}

                                        </div>
                                    </Accordion>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="javascript:void(0);" id="secondNavbarDrop" data-toggle="dropdown">Resources</a>
                                <div className='dropdown-menu'>
                                    <Link className={`dropdown-item fancyLinks ${location.pathname === '/resources/books' ? ('activeLink') : ('')}`} to='/resources/books'><span className="linkTHEff">Books</span></Link>
                                    <Link className={`dropdown-item fancyLinks ${location.pathname === '/resources/softwares' ? ('activeLink') : ('')}`} to='/resources/softwares'><span className="linkTHEff">Softwares</span></Link>
                                    <Link className={`dropdown-item fancyLinks ${location.pathname === '/resources/classvideos' ? ('activeLink') : ('')}`} to='/resources/classvideos'><span className="linkTHEff">Class Videos</span></Link>
                                    <Link className={`dropdown-item fancyLinks ${location.pathname === '/seminars' ? ('activeLink') : ('')}`} to='/seminars'><span className="linkTHEff">Seminars</span></Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                {!localStorage.getItem("authToken") ? (
                                    <Link className={`nav-link ${location.pathname === '/student/login' ? ('activeLink') : ('')}`} to='/student/login'>Login</Link>
                                ) : (
                                    <div>
                                        <a className={`nav-link ${location.pathname === '/resources' ? ('activeLink') : ('')}`} href="javascript:void(0);" id="secondNavbarDrop" data-toggle="dropdown">Profile</a>
                                        <div className='dropdown-menu'>
                                            <Link className={`dropdown-item fancyLinks ${location.pathname === '/resources' ? ('activeLink') : ('')}`} to='/resources'><span className="linkTHEff">Profile</span></Link>
                                            <a type='button' onClick={handleLogOut} className="dropdown-item btn fancyLinks" ><span className="linkTHEff">Logout</span></a>
                                        </div>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className='container-fluid d-lg-none'>
                <div className='secNavMobileBox' style={{ padding: "10px 30px" }}>
                    <div className='row'>
                        <div className='col-4'>
                            <i className="fa-solid fa-bars" style={{ color: "#d72323", marginRight: "10px", fontSize: "25px", marginTop: "8px", marginLeft: "15px" }} onClick={openNav}></i>
                        </div>
                        <div className='col-4'>
                            <Link className="navbar-brand" to='/'> <img src={mainLogo} style={{ width: "75px", display: "block", margin: "auto" }} alt='CADD CORNER BD' className='img-fluid headerLogo' /> </Link>
                        </div>
                        <div className='col-4'>
                            {!localStorage.getItem("authToken") ? (
                                <Link to='/student/login' style={{ color: "#d72323", display: "block", marginLeft: "auto", width: "max-content", marginTop: "7px", fontSize: "17px", fontWeight: "500" }}>Login</Link>
                            ) : (
                                <i className="fa-solid fa-user" style={{ color: "#d72323", fontSize: "25px", marginTop: "8px", textAlign:"right", width:"100%" }} onClick={openProfileNav}></i>
                            )}

                        </div>
                        <div className='col-12'>
                            <div id="myNav" className="overlay" ref={myNav}>
                                <a href="javascript:void(0)" className="closebtn" onClick={closeNav} style={{ fontSize: "40px" }}>&times;</a>
                                <div className="overlay-content">
                                    <Link to='/' className=''>Home</Link>
                                    <Link to='/stdntschlrship' className=''>Student Scholarship</Link>
                                    <Accordion>
                                        <Accordion.Item eventKey="0" style={{ background: "transparent", border: "none" }}>
                                            <Accordion.Header>
                                                <p style={{ color: "#818181", fontSize: "15px" }}>Student's Corner</p></Accordion.Header>
                                            <Accordion.Body style={{ border: "none", padding: "3px 8px" }}>
                                                <Link to='/requestforcertificate'>Request for Certificate</Link>
                                                <Link to='/verifycertificate'>Verify Certificate</Link>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion>
                                        <Accordion.Item eventKey='0' style={{ background: "transparent", border: "none" }}>
                                            <Accordion.Header>
                                                <p style={{ color: "#818181", fontSize: "15px" }}>Courses</p>
                                            </Accordion.Header>
                                            <Accordion.Body style={{ border: "none", padding: "3px 8px" }}>
                                                <Accordion>
                                                    {courseCatagories.map((catagory, el) => {
                                                        return <Accordion.Item eventKey={el} style={{ background: "transparent", border: "none" }}>
                                                            <Accordion.Header>
                                                                <p style={{ color: "#818181", fontSize: "15px" }}>{catagory.catagory}</p>
                                                            </Accordion.Header>
                                                            <Accordion.Body style={{ border: "none", padding: "3px 8px" }}>
                                                                {courseNames.map((course) => {
                                                                    while (course.catagory === catagory.catagory) {
                                                                        return <div key={course.id}>
                                                                            <Link to={`/course/${course.id}`}>{course.name}</Link>
                                                                        </div>
                                                                    }
                                                                })}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    })}
                                                </Accordion>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    <Accordion>
                                        <Accordion.Item eventKey="0" style={{ background: "transparent", border: "none" }}>
                                            <Accordion.Header>
                                                <p style={{ color: "#818181", fontSize: "15px" }}>Resources</p></Accordion.Header>
                                            <Accordion.Body style={{ border: "none", padding: "3px 8px" }}>
                                                <Link to='/resources/books'>Books</Link>
                                                <Link to='/resources/softwares'>Softwares</Link>
                                                <Link to='/resources/classvideos'>Class Videos</Link>
                                                <Link to='/seminars'>Seminars</Link>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <div id="profileNav" className="overlay" ref={profileNav}>
                                <a href="javascript:void(0)" className="closebtn" onClick={closeProfileNav} style={{ fontSize: "40px" }}>&times;</a>
                                <div className="overlay-content">
                                    <Link to='/resources' className=''>Profile</Link>
                                    <a type='button' onClick={handleLogOut} className=''>Logout</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecondNavbar
