import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import FirstNav from './FirstNav';
import Home from './Home';
import SecondNavbar from './SecondNavbar';
import Location from './Location';
import Footer from './Footer';
import StudentScholarship from './StudentScholarship';
import CourseItem from './CourseItem';
import StudentLogin from './StudentLogin';
import BooksContainer from './BooksContainer';
import ClassVideosContainer from './ClassVideosContainer';
import SoftwaresContainer from './SoftwaresContainer';
import Resources from './Resources';
import ApplyForAdmission from './ApplyForAdmission';
import Seminars from './Seminars';
import RequestCertificate from './RequestCertificate';
import VerifyCertificate from './VerifyCertificate';

const Layout = () => {
  let location = useLocation();
  return (
    <div>
      {location.pathname === '/'?(<FirstNav/>):('')}
      <SecondNavbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/stdntschlrship' element={<StudentScholarship/>}/>
        <Route path='/applyforad' element={<ApplyForAdmission/>}/>
        <Route path='/course/:id' element={<CourseItem/>}/>
        <Route path='/student/login' element={<StudentLogin/>}/>
        <Route path='/resources' element={<Resources/>}/>
        <Route path='/resources/books' element={<BooksContainer/>}/>
        <Route path='/resources/classvideos' element={<ClassVideosContainer/>}/>
        <Route path='/resources/softwares' element={<SoftwaresContainer/>}/>
        <Route path='/seminars' element={<Seminars/>}/>
        <Route path='/requestforcertificate' element={<RequestCertificate/>}/>
        <Route path='/verifycertificate' element={<VerifyCertificate/>}/>
      </Routes>
      <Location/>
      <Footer/>
    </div>
  )
}

export default Layout
