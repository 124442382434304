// eslint-disable-next-line
import React, { useEffect } from 'react'


const ScholarshipItem = (props) => {
    const {scholarship, sl} = props;
    return (
        <div>
            <h4 className="font-weight-bold sutReg banFont" style={{ color: "red", fontWeight: "600" }}>{sl < 10?('0'+sl):('sl')} : {scholarship.schlrship}</h4>
            <img src={`https://api.caddcornerbd.com/schlrships/schlrImg/${scholarship.thumblink}`} alt="Seminar" className="img-fluid" /><br /><br />
            <h5 className="sutReg font-weight-bold">Description</h5><hr />
            <p className="banFont" style={{ whiteSpace: "pre-line", borderBottom: "1px solid #000", paddingBottom: "45px", marginBottom:"20px" }}>{scholarship.schlrdes}
            </p>
        </div>
    )
}

export default ScholarshipItem
