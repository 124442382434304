/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const ClassVideos = () => {

  let navigate = useNavigate();
  const [validity, setValidity] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});
  const [videos, setVideos] = useState([]);

  //fetch student information
  const fetchStudentInfo = async () => {
    let token = localStorage.getItem("authToken");
    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/fetchaa/${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setStudentInfo(json.result[0]);
    if (json.validity) {
      setValidity(true);
    }
  }

  //fetch softwares
  const fetchVideos = async () => {
    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/fetchvideos`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setVideos(json.result);
  }

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/student/login");
    }
    fetchStudentInfo();
    fetchVideos();
  })

  return (
    <div>
      {validity && <div className='container'>
        <h1 style={{ color: "red", textAlign: "center", marginTop: "20px", fontWeight: "bold" }}>Videos</h1>
        <div className='booksContainer' style={{ border: "1px solid", margin: "90px", padding: "40px", marginTop: "20px" }}>
          {videos.map((video) => {
            if (video.batch === studentInfo.batch) {
              return <div key={video.id} className='singleBook' style={{ border: "1px solid", padding: "15px", margin: "10px" }}>
                <div className='row'>
                  <div className='col-lg-6'>
                    <p>{video.videotitle}</p>
                  </div>
                  <div className='col-lg-4'>
                    <p>Date: {video.dateofclass}</p>
                  </div>
                  <div className='col-lg-2'>
                    <a href={`${video.link}`} target="_blank">
                      Open &nbsp;
                      <i
                        className="fa-solid fa-arrow-up-right-from-square"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            }
          })}
        </div>
      </div>}
    </div>
  )
}

export default ClassVideos
