import React from 'react'
import io from "socket.io-client";
import { useEffect } from 'react';
import { useState } from 'react';
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const RequestCertificate = () => {
    const alert = useAlert();

    const [studentid, setStudentid] = useState("");
    const [loading, setLoading] = useState(false);

    const handlerequestforcertificate = async (e) => {
        e.preventDefault()
        setLoading(true);
        const response = await fetch(`https://api.caddcornerbd.com/api/students/requestforcertificate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                studentid
            }),
        });

        const json = await response.json();
        if (json.success) {
            setLoading(false);
            setStudentid("");
            alert.success("Request Sent");
            socket.emit("request_forcertificate", {
                message: "requested for certificate"
            })
        }else if(json.error) {
            setLoading(false);
            alert.error(json.errorMsg);
        }
    }

    const onChangestudentidinput = (e) => {
        setStudentid(e.target.value);
    }

    useEffect(() => {
    })

    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className="jumbotron ins_jumbo" style={{ marginTop: "50px" }}>
                        <div className="instructions">
                            <p>
                                সার্টিফিকেট এর জন্য আবেদন করতে নিচের ফরমটি পূরণ করে সাবমিট করুন। সর্বোচ্চ ১৫ কর্ম দিবসের মধ্যে আপনার
                                সাথে যোগাযোগ করা হবে। তাছাড়া কোনো সমস্যার সম্মুখীন হলে 01841-319627 এই নাম্বারে যোগাযোগ করতে পারেন।
                            </p>
                        </div>
                    </div>
                    <div className="jumbotron main_jumbo" style={{ marginTop: "50px", marginBottom: "50px", padding: "50px" }}>
                        <h1 className="admiTitle">Request For Certificate</h1>

                        <form onSubmit={handlerequestforcertificate}>
                            <div className="form-group">
                                <label htmlFor="studentid">Student ID:<sup className="reqStar">*</sup></label>
                                <input type="text" className="form-control" placeholder="Student ID" name="studentid" id="studentid" value={studentid} onChange={onChangestudentidinput}
                                    autoComplete="off" required />
                            </div>
                            <button type="submit" className="btn btn-submit-admit" style={{ backgroundColor: "red", color: "#fff", border: "1px solid red", display: "block", margin: "auto" }}>
                                {loading?('Please wait...'):('Submit')}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestCertificate
