import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import { Link } from 'react-router-dom';
import heroVideo from '../img/headVideo.mp4';
import '../css/home.css';
import scheduleIcon from '../img/scheduleIcon.png';
import oneToOneIcon from '../img/onetooneIcon.png';
import courseFee from '../img/feesIcon.png';

const socket = io.connect("https://api.caddcornerbd.com");

const Home = () => {
    const [companies, setCompanies] = useState([]);

    const fetchCompanies = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/others/fetchcompanies",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCompanies(json.result);
    }


    useEffect(() => {
        fetchCompanies();
        socket.on("added_company", (data) => {
            fetchCompanies();
        });
        socket.on("updated_company", (data) => {
            fetchCompanies();
        });
        socket.on("deleted_company", (data) => {
            fetchCompanies();
        });
    }, [])
    return (
        <div>
            <div className="container-fluid sec-hero p-0">
                <div id="carouselExampleIndicators" className="carousel slide p-0" data-ride="carousel">
                    <div className="carousel-inner" style={{
                        width: "100%",
                        maxHeight: "750px"
                    }}>
                        <div className="carousel-item active">
                            <video autoPlay muted loop id="myVideo" className="d-block w-100">
                                <source src={heroVideo} type="video/mp4" />
                            </video>
                            <div className="carousel-caption d-md-block" style={{ left: "7%", top: "20%", height: "100%", textAlign: "left" }}>
                                <h1 className="d-none d-md-block popSemi h1-1">Want to build your profession with Cad?</h1>
                                <h3 className="popSemi">Learn online with us!</h3>
                                <Link className="btn btn-light text-danger popSemi" to="/applyforad"><i className="fas fa-graduation-cap"></i> &nbsp;Enroll Now</Link>
                                <br />
                                <br />
                                <br />
                                <p className="d-none d-md-block">Need any help?<br />Contact Now : 01841-319627</p>
                                <h5 className="d-none d-md-block" style={{ color: "red", textShadow: "1px 1px #fff" }}>Payment Options</h5>
                                <p className="d-none d-md-block">Bkash : 01919-319627<br />Nagad : 01841-319627<br />Rocket : 01841-319627</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid sec-3">
                <div className="container sec-3-container">
                    <div className="row">
                        <div className="col-lg-4">
                            <Link to='/applyforad' className='noTextDec'>
                                <div className="sec-3-in-mainBox">
                                    <div className="sec-3-in-subBox">
                                        <i className="fas fa-users"></i>
                                        <h6>ADMISSIONS</h6>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-4">
                            <a href="l" className='noTextDec'>
                                <div className="sec-3-in-mainBox">
                                    <div className="sec-3-in-subBox">
                                        <i className="fas fa-shopping-basket"></i>
                                        <h6>RESOURCES</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4">
                            <div className="sec-3-in-mainBox">
                                <div className="sec-3-in-subBox">
                                    <i className="fas fa-comments"></i>
                                    <h6>CONTACT US</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid whychoose-fluid">
                <div className="container whychoose-con">
                    <div className="whychoose-conIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Why Choose <span>Cadd Corner</span>?</h2>
                            </div>
                            <div className="col-lg-4">
                                <div className="whychooseSingle">
                                    <img src={scheduleIcon} className="img-fluid" alt='Schedule' />
                                    <h4>Flexible Schedule</h4>
                                    <p>Learn anytime, anywhere and even at your place through online</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="whychooseSingle">
                                    <img src={oneToOneIcon} className="img-fluid" alt='Feedback' />
                                    <h4>One-to-One Feedback</h4>
                                    <p>Your work will reviewed by your instructor</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="whychooseSingle">
                                    <img src={courseFee} className="img-fluid" alt='Course Fee' />
                                    <h4>Affordable Course Fee</h4>
                                    <p>Quality training for a fraction of the cost of traditional curriculum</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid regWithFluid">
                <div className="container regWith-con">
                    <div className="regWith-conIn">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <h2 style={{ color: "#000" }}>REGISTERED WITH</h2>
                            </div>
                            {companies.map((company) => {
                                return <div key={company.id} className="col-4">
                                    <img src={`https://api.caddcornerbd.com/schlrships/companyImg/${company.logo}`} className="img-fluid ad-img registeredWithImages" alt='registered with' />
                                </div>
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
