import React, { useRef } from 'react'
import io from "socket.io-client";
import { useState } from 'react';
import { useAlert } from "react-alert";
import { FacebookEmbed } from 'react-social-media-embed';
// eslint-disable-next-line no-unused-vars
const socket = io.connect("https://api.caddcornerbd.com");

const VerifyCertificate = () => {

    const alert = useAlert();
    const [studentid, setStudentid] = useState("");
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);

    const [name, setName] = useState("");
    const [coursename, setCoursename] = useState("");

    const [posts, setPosts] = useState([]);

    // refs
    const viewCertificateModal = useRef(null);
    const closeViewCertificateModal = useRef(null);
    // refs


    const handleVerifyCertificate = async (e) => {
        e.preventDefault();
        setLoading(true);
        const response = await fetch(`https://api.caddcornerbd.com/api/students/verifycertificate`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                studentid
            }),
        });

        const json = await response.json();
        if (json.certified) {
            setLoading(false);
            // console.log(json.fileUrl);
            setName(json.name);
            setCoursename(json.coursename);
            setPosts(json.posts);
            setValid(true);
        } else if (json.error) {
            setLoading(false);
            alert.error(json.errorMsg);
        }

    }

    const onChangestudentidinput = (e) => {
        setStudentid(e.target.value);
    }

    return (
        <div>
            {/* View certificate Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editBatchModal"
                ref={viewCertificateModal}
            >
                Launch Edit Batch modal
            </button>

            <div
                className="modal fade"
                id="editBatchModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editBatchModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                        <div className='modal-header'>
                            <h4>Certificate of {name}</h4>
                        </div>
                        <div className="modal-body">
                            <div className='row'>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                style={{ borderRadius: "0" }}
                                ref={closeViewCertificateModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* View certificate Modal */}
            <div className='container-fluid'>
                <div className='container'>
                    {!valid ? (
                        <div className="jumbotron main_jumbo" style={{ marginTop: "50px", marginBottom: "50px", padding: "50px" }}>
                            <h1 className="admiTitle">Verify Certificate</h1>

                            <form onSubmit={handleVerifyCertificate}>
                                <div className="form-group">
                                    <label htmlFor="studentid">Student ID:<sup className="reqStar">*</sup></label>
                                    <input type="text" className="form-control" placeholder="Student ID" name="studentid" id="studentid" value={studentid} onChange={onChangestudentidinput}
                                        autoComplete="off" required />
                                </div>
                                <button type="submit" className="btn btn-submit-admit" style={{ backgroundColor: "red", color: "#fff", border: "1px solid red", display: "block", margin: "auto" }}>
                                    {loading ? ('Please wait...') : ('Submit')}
                                </button>
                            </form>
                        </div>
                    ) : (
                        <div className="jumbotron main_jumbo" style={{ marginTop: "50px", marginBottom: "50px", padding: "50px" }}>
                            <button type='button' className='btn btn-danger' onClick={() => { setStudentid(""); setValid(false); }} style={{ margin: "15px 0" }}>Verify another student</button><br />
                            <a type='button' className='btn btn-success' href={`https://api.caddcornerbd.com/schlrships/certificates/${studentid + "-" + coursename}.pdf`} style={{ margin: "15px 0" }}>View Certificate</a>
                            <h4 className="admiTitle" style={{ color: "green" }}>{name} is certified in {coursename} course.</h4>
                            <p>His/Her works:</p>
                            <br />
                            <div className='row'>
                                {posts.map((post) => {
                                    return <div className='col-lg-6'>
                                        <div key={post.id} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <FacebookEmbed url={`${post.postlink}`} />
                                        </div>
                                    </div>

                                })}

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VerifyCertificate
