import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import io from "socket.io-client";
import Resizer from "react-image-file-resizer";
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const Resources = () => {
    const alert = useAlert();
    let navigate = useNavigate();


    const [studentInfo, setStudentInfo] = useState({});
    const [validity, setValidity] = useState(false);
    const [passUpdateInfo, setPassUpdateInfo] = useState({ oldPass: "", newPass: "", conNewPass: "" })

    //edit Course details states
    const [selectedEditDp, setSelectedEditDp] = useState(null);

    // refs
    const openEditDpModal = useRef(null);
    const closeEditDpModal = useRef(null);

    // refs
    const openEditPassModal = useRef(null);
    const closeEditPassModal = useRef(null);
    // refs

    //fetch student information
    const fetchStudentInfo = async () => {
        let token = localStorage.getItem("authToken");
        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/fetchaa/${token}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setStudentInfo(json.result[0]);
        if (json.validity) {
            setValidity(true);
        }
    }

    //image resizer
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300
            );
        });

    // data to file
    const dataURLtoFile = (dataurl, filename) => {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //on change thumbnail
    const onChangeEditDp = async (e) => {

        const file = e.target.files[0];
        const imageResized = await resizeFile(file);

        const imageFile = dataURLtoFile(imageResized, file.name)
        setSelectedEditDp(imageFile);
    }

    //handle update pass
    const handleUpdatePass = async (e) => {
        e.preventDefault();
        const { oldPass, newPass, conNewPass } = passUpdateInfo;
        let id = studentInfo.id;
        if (studentInfo.studentpass !== oldPass) {
            alert.error("The current password you've entered is wrong")
        } else {
            if (newPass !== conNewPass) {
                alert.error("Please enter the new password twice correctly")
            } else {
                const response = await fetch(
                    `https://api.caddcornerbd.com/api/students/updatepass`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id,
                            newPass
                        }),
                    }
                );

                const json = await response.json();
                if (json.success) {
                    fetchStudentInfo();
                    closeEditPassModal.current.click();
                    alert.success('Password Updated Successfully');
                }
            }
        }
    }

    //handle update dp
    const handleUpdateDp = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', selectedEditDp);
        formData.append('id', studentInfo.id);

        const response = await fetch(
            `https://api.caddcornerbd.com/api/students/updatedp`,
            {
                method: "PUT",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                body: formData,
            }
        );

        const json = await response.json();
        if (json.success) {
            fetchStudentInfo();
            closeEditDpModal.current.click();
            setSelectedEditDp(null);
            alert.success('Your Profile Updated Successfully');
            socket.emit("update_studentdp", {
                message: "studentdp Updated"
            })
        }
    }

    //on change pass update info
    const onChangePassUpdateInput = (e) => {
        setPassUpdateInfo({ ...passUpdateInfo, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/student/login");
        }
        fetchStudentInfo();
        // eslint-disable-next-line
        socket.on("updated_student", (data) => {
            fetchStudentInfo();
        });
    });
    return (
        <div id='resourcesComponent'>
            {/* Edit Dp Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editDpModal"
                ref={openEditDpModal}
            >
                Launch Edit Dp modal
            </button>

            <div
                className="modal fade"
                id="editDpModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editDpModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdateDp}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Profile Photo</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <input className="form-control" type="file" id="studentdp" name='studentdp' onChange={onChangeEditDp} accept=".jpg, .jpeg, .png" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditDpModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Dp Modal */}
            {/* Edit Password Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#editPassModal"
                ref={openEditPassModal}
            >
                Launch Edit Password modal
            </button>

            <div
                className="modal fade"
                id="editPassModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="editPassModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-md">
                    <form onSubmit={handleUpdatePass}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4>Edit Password</h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <label htmlFor="name" className="form-label">Current Password:</label>
                                        <input className="form-control" type="text" id="oldPass" name='oldPass' value={passUpdateInfo.oldPass} onChange={onChangePassUpdateInput} required /><br />
                                        <label htmlFor="name" className="form-label">New Password:</label>
                                        <input className="form-control" type="text" id="newPass" name='newPass' value={passUpdateInfo.newPass} onChange={onChangePassUpdateInput} required /><br />
                                        <label htmlFor="name" className="form-label">Confirm New Password:</label>
                                        <input className="form-control" type="text" id="conNewPass" name='conNewPass' value={passUpdateInfo.conNewPass} onChange={onChangePassUpdateInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeEditPassModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Edit Password Modal */}
            {validity && <div className="container-fluid">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className="jumbotron main_jumbo" style={{ padding: "50px", marginTop: "50px", marginLeft: "0px", marginRight: "0px" }}>
                                <div className='dpContainer' style={{ width: "180px", height: "180px", margin: "auto", borderRadius: "50%", overflow: "hidden" }}>
                                    <img style={{ width: "180px" }} src={studentInfo.studentdp === null ? (`https://api.caddcornerbd.com/schlrships/stImg/placeholder.png`) : (`https://api.caddcornerbd.com/schlrships/stImg/${studentInfo.studentdp}`)} alt="dp" className='img-fluid' />
                                </div>
                                <button className='btn btn-danger' onClick={() => { openEditDpModal.current.click() }} style={{ borderRadius: "0", fontSize: "60%", display: "block", margin: "auto", marginTop: "10px" }}>Edit Photo</button>
                                <button className='btn btn-danger' onClick={() => { openEditPassModal.current.click() }} style={{ borderRadius: "0", fontSize: "60%", display: "block", margin: "auto", marginTop: "10px" }}>Change Password</button>
                                <h2>Hi! {studentInfo.name}</h2>
                                <h3 style={{ color: "red" }}>Following services are unlocked for you:</h3>
                                <div className="services-section mx-auto" style={{width:"100%"}}>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <Link to="/resources/books" className="btn btn-info" style={{ margin: "10px", borderRadius: "0" }}>Books</Link>
                                        </div>
                                        <div className='col-lg-4'>
                                            <Link to="/resources/softwares" className="btn btn-warning" style={{ margin: "10px", borderRadius: "0" }}>Softwares</Link>
                                        </div>
                                        <div className='col-lg-4'>
                                            <Link to="/resources/classvideos" className="btn btn-success" style={{ margin: "10px", borderRadius: "0" }}>Tutorial Videos</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='jumbotron main_jumbo' style={{ padding: "20px", marginBottom: "100px", marginTop: "50px", marginLeft: "0px", marginRight: "0px" }}>
                                <h6>ID: <b>{studentInfo.studentid}</b></h6>
                                <h6>Course: <b>{studentInfo.coursename}</b></h6>
                                <h6>Email: <b>{studentInfo.email}</b></h6>
                                <h6>Batch: {studentInfo.batch === "" ? (<span style={{ color: "grey" }}>No batch assigned to you. Please contact your respected teacher.</span>) : (<b>{studentInfo.batch}</b>)}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </div>
    )
}

export default Resources
