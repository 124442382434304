import React from 'react';
import bkashLogo from '../img/bkash.png';
import cadFootLogo from '../img/cadFootLogo.png';
import { Link } from 'react-router-dom';
import '../css/footer.css';

const Footer = () => {
    return (
        <div>
            <div className="container-fluid footer-fluid">
                <div className="container footer-con">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="footer-col-1">
                                <img src={cadFootLogo} className="img-fluid cadFootLogo" alt='cadFootLogo' />
                                <br />
                                <div className="socialIcons">
                                    <p>Our Social Links</p>
                                    <a href="l"><i className="fab fa-facebook"></i></a>
                                    <a href="l"><i className="fab fa-linkedin"></i></a>
                                    <a href="l"><i className="fab fa-youtube"></i></a>
                                </div>
                                <div className="paymentOptions">
                                    <p>Our Payment Options</p>
                                    <img src={bkashLogo} className="img-fluid" alt='bkash' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-col-3">
                                <p>Address</p>
                                <address>
                                    CADD CORNER Training Institute.<br />
                                    1320, Kashem Villa (ground floor)<br />
                                    East Nasirabad, CDA Avenue, Chittagong 4203
                                </address>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-col-2">
                                <p>Student's Corner</p>
                                <Link to='/verifycertificate'>Certificate Verify</Link><br />
                                <Link to=''>Service Request Form</Link><br />
                                <Link to=''>Student Complain Form</Link><br />
                                <Link to=''>Civil Box</Link><br />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-col-4">
                                <p>Resources</p>
                                <Link to='/applyforad'>Admissions</Link><br />
                                <Link to='/seminars'>Seminar/Workshop</Link><br />
                                <Link to='/resources/softwares'>Softwares</Link><br />
                                <Link to=''>Student Success Stories</Link><br />
                                <Link to=''>Schedule</Link><br />
                                <Link to=''>Sample Vieos</Link><br />
                                <Link to=''>Student's Work</Link><br />
                                <Link to=''>Reviews</Link><br />
                                <Link to=''>FAQs</Link><br />
                                <Link to=''>Blog</Link><br />
                                <Link to=''>Content</Link><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
