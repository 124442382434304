import React from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import YouTube from 'react-youtube';
import { useAlert } from "react-alert";
import io from "socket.io-client";

const socket = io.connect("https://api.caddcornerbd.com");

const CourseItem = () => {

    const alert = useAlert();
    const [course, setCourse] = useState([]);
    const [videoIdState, setVideoIdDtate] = useState("");

    const [applyCourse, setApplyCourse] = useState({ name: "", email: "", mobile: "" })

    const [oldParam, setOldParam] = useState(null);

    const viewCourseApplyModal = useRef(null);
    const closeApplyCourseModal = useRef(null);

    let location = useLocation();

    const fetchCourses = async () => {
        let id = location.pathname.substring(8);
        const response = await fetch(
            `https://api.caddcornerbd.com/api/courses/fetch/item/${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );
        const json = await response.json();
        setCourse(json.result[0]);
        setVideoIdDtate(json.result[0].videoid);
        setOldParam(location.pathname);
    }

    const handleApplyCourse = async (e) => {
        const { name, email, mobile } = applyCourse;
        e.preventDefault()

        const response = await fetch(`https://api.caddcornerbd.com/api/applications/apply`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                mobile,
                coursename: course.name + " (" + course.catagory + ")"
            }),
        });

        const json = await response.json();
        if (json.success) {
            closeApplyCourseModal.current.click();
            alert.success("Successfully applied!");
            socket.emit("apply_foradmission", {
                message: "applied for admission"
            });
            setApplyCourse({
                name: "",
                email: "",
                mobile: ""
            });
        }
    }


    const opts = {
        height: '390',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
        },
    };

    const openApplyCourseModal = (e) => {
        viewCourseApplyModal.current.click();
    }

    const _onReady = (e) => {
        // access to player in all event handlers via event.target
        e.target.pauseVideo();
    }

    const onChangeApplyCourseInput = (e) => {
        setApplyCourse({ ...applyCourse, [e.target.name]: e.target.value })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useMemo(() => fetchCourses(), [oldParam, location.pathname]);

    useEffect(() => {
        fetchCourses();
        socket.on("updated_course", (data) => {
            fetchCourses();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div>
            {/* Add Course Modal */}
            <button
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#addCourseModal"
                ref={viewCourseApplyModal}
            >
                Launch Course apply modal
            </button>

            <div
                className="modal fade"
                id="addCourseModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="addCourseModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg">
                    <form onSubmit={handleApplyCourse}>
                        <div className="modal-content banFont" style={{ borderRadius: "0" }}>
                            <div className='modal-header'>
                                <h4><b style={{ color: "#d72323" }}>Apply for Admission</b></h4>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <p style={{ textAlign: "center", backgroundColor: "#d72323", color: "#fff", padding: "5px", borderRadius: "5px" }}>আমাদের কোর্স নিতে নিচের ফরমটি পূরণ করে সাবমিট করুন। সর্বোচ্চ ০১ কর্ম দিবসের মধ্যে আপনার সাথে যোগাযোগ করা হবে। তাছাড়া কোনো সমস্যার সম্মুখীন হলে 01841-319627 এই নাম্বারে যোগাযোগ করতে পারেন।</p>
                                        <div className='priceBox'>
                                            <h5 style={{ textAlign: "center", padding: "10px", margin: "18px 0", borderRadius: "24px", border: "2px solid #d72323" }}>Price: <del style={{ fontSize: "60%", color: "grey" }}>{course.price}</del> {course.discountedprice} tk </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <h6 style={{ fontFamily: "poppins", fontWeight: "600", color: "grey" }}>Course: {course.name} ({course.catagory})</h6>
                                        <br />
                                        <input className="form-control" type="text" id="name" name='name' value={applyCourse.name} placeholder="Name" onChange={onChangeApplyCourseInput} required /><br />
                                        <input className="form-control" placeholder="Email" type="text" id="email" name='email' value={applyCourse.email} onChange={onChangeApplyCourseInput} required /><br />
                                        <input className="form-control" placeholder="Mobile" type="text" id="mobile" name='mobile' value={applyCourse.mobile} onChange={onChangeApplyCourseInput} required /><br />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ borderRadius: "0" }}
                                    ref={closeApplyCourseModal}
                                >
                                    Close
                                </button>
                                <button type='submit' className="btn btn-success" style={{ borderRadius: "0", backgroundColor: "#d72323", border: "1px solid #d72323" }}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* Add Course Modal */}
            <div className="container-fluid seminarFirstFluid">
                <div className="container seminarFirstCon" style={{ paddingTop: "30px" }}>
                    <h1 style={{ fontWeight: "600", backgroundColor: "#d72323", textAlign: "left", padding: "10px", color: "#fff" }}>
                        {course.name} <span style={{ fontSize: "20px", color: "#eee", marginLeft: "10px" }}>Catagory: {course.catagory}</span>
                    </h1>
                </div>
            </div>
            <div className="container-fluid seminarSecFluid banglaFont">
                <div className="container seminarSecCon">
                    <div className="row">
                        <div className='col-lg-6'>
                            <div className='yllLearnBox d-none d-lg-block' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", borderTopRightRadius: "20px", borderTopLeftRadius: "20px", backgroundColor: "#d72323", color: "#fff", borderColor: "#d72323" }}>
                                <h3 style={{ fontFamily: "poppins" }}>You'll learn:</h3>
                                <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.ulearn}</p>
                            </div>
                            <div className='requirementsBox d-none d-lg-block' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                <h4 style={{ fontFamily: "poppins" }}>Requirements:</h4>
                                <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.requirements}</p>
                            </div>
                            <div className='requirementsBox d-none d-lg-block' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                <h4 style={{ fontFamily: "poppins" }}>Description:</h4>
                                <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.description}</p>
                            </div>
                            <div className='requirementsBox d-none d-lg-block' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                <h4 style={{ fontFamily: "poppins" }}>This course is for:</h4>
                                <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.courseisfor}</p>
                            </div>
                            <div className='requirementsBox d-none d-lg-block' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", backgroundColor: "#d72323", color: "#fff", borderColor: "#d72323" }}>
                                <h4 style={{ fontFamily: "poppins" }}>Course content:</h4>
                                <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.coursecontent}</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="column8">
                                <div className="seminarInCon">
                                    <img src={`https://api.caddcornerbd.com/schlrships/courseImg/${course.thumbnail}`} alt="Course" className="img-fluid" /><br /><br />
                                </div>
                            </div>
                            <div className='requirementsBox d-lg-none' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                <h4 style={{ fontFamily: "poppins" }}>Description:</h4>
                                <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.description}</p>
                            </div>
                            <div className='column8 d-lg-none'>
                                <div className="column4">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className="seminarInCon" style={{ width: "max-content" }}>
                                                <h4>Price: <del style={{ fontSize: "60%", color: "grey" }}>{course.price}</del> {course.discountedprice} tk</h4>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <button className='btn btn-info d-block' style={{ marginLeft: "auto", borderRadius: "0", backgroundColor: "#d72323", color: "#fff", border: "1px solid #d72323" }} type='button' onClick={openApplyCourseModal}>Enroll</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column8">
                                <div className="seminarInCon">
                                    <YouTube key={videoIdState} videoId={videoIdState} opts={opts} onReady={_onReady} />
                                </div>
                            </div>
                            <div className='column8'>
                                <div className='yllLearnBox d-lg-none' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", borderTopRightRadius: "20px", borderTopLeftRadius: "20px", backgroundColor: "#d72323", color: "#fff", borderColor: "#d72323" }}>
                                    <h3 style={{ fontFamily: "poppins" }}>You'll learn:</h3>
                                    <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.ulearn}</p>
                                </div>
                                <div className='requirementsBox d-lg-none' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                    <h4 style={{ fontFamily: "poppins" }}>Requirements:</h4>
                                    <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.requirements}</p>
                                </div>
                                <div className='requirementsBox d-lg-none' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px" }}>
                                    <h4 style={{ fontFamily: "poppins" }}>This course is for:</h4>
                                    <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.courseisfor}</p>
                                </div>
                                <div className='requirementsBox d-lg-none' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px", backgroundColor: "#d72323", color: "#fff", borderColor: "#d72323" }}>
                                    <h4 style={{ fontFamily: "poppins" }}>Course content:</h4>
                                    <p style={{ textAlign: "justify", whiteSpace: "pre-line" }}>{course.coursecontent}</p>
                                </div>
                                <div className='requirementsBox' style={{ padding: "15px", border: "1px solid lightgrey", lineHeight: "1.3", marginBottom: "15px", marginTop: "10px" }}>
                                    <h3 style={{ fontFamily: "poppins", marginLeft: "15px" }}>This course includes:</h3>
                                    <div className='courseIncludesBox' style={{ fontSize: "18px", lineHieght: "1.7", color: "#d72323" }}>
                                        <ul style={{ listStyleType: "none" }}>
                                            <li>
                                                <i class="fa-solid fa-file-video"></i> &nbsp; 44.5 hours on-demand video
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-bucket"></i> &nbsp; 32 downloadable resources
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-clock"></i> &nbsp; Full lifetime access
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-wifi"></i> &nbsp; Access on mobile and TV
                                            </li>
                                            <li>
                                                <i class="fa-solid fa-certificate"></i> &nbsp; Certificate of completion
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='column8 d-none d-lg-block'>
                                <div className="column4">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className="seminarInCon" style={{ width: "max-content" }}>
                                                <h4>Price: <del style={{ fontSize: "60%", color: "grey" }}>{course.price}</del> {course.discountedprice} tk</h4>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <button className='btn btn-info d-block' style={{ marginLeft: "auto", borderRadius: "0", backgroundColor: "#d72323", color: "#fff", border: "1px solid #d72323" }} type='button' onClick={openApplyCourseModal}>Enroll</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseItem
