import React from 'react'

const Location = () => {
    return (
        <div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3689.7341565109723!2d91.81952631447712!3d22.36366464624419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30acd9a080d9c227%3A0x61e6ba3a1f39c874!2sCADD%20CORNER!5e0!3m2!1sen!2sbd!4v1643378118538!5m2!1sen!2sbd"
                width="100%" height="300" style={{ border: "0", marginBottom: "0" }} allowFullScreen="" loading="lazy" title='caddCornerLocation'></iframe>
        </div>
    )
}

export default Location
