// eslint-disable-next-line
import React, { useEffect } from 'react'

const SeminarItem = (props) => {
    const { seminar, sl } = props;
    return (
        <div>
            <h4 className="font-weight-bold sutReg banFont" style={{ color: "red", fontWeight: "600" }}>{sl < 10 ? ('0' + sl) : ('sl')} : {seminar.title}</h4>
            <img src={`https://api.caddcornerbd.com/schlrships/seminarImg/${seminar.thumblink}`} alt="Seminar" className="img-fluid" /><br /><br />
            <h5><b>Date of Seminar: {seminar.dateofsemi}</b></h5>
            <h5 className="sutReg font-weight-bold">Description</h5><hr />
            <p className="banFont" style={{ whiteSpace: "pre-line", borderBottom: "1px solid #000", paddingBottom: "45px", marginBottom: "20px" }}>{seminar.semides}
            </p>
        </div>
    )
}

export default SeminarItem
