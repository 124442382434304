/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Softwares = () => {
  let navigate = useNavigate();
  const [softwares, setSoftwares] = useState([]);

  //fetch softwares
  const fetchSoftwares = async () => {
    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/fetchsoftwares`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setSoftwares(json.result);
  }

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/student/login");
    }
    fetchSoftwares();
  })

  return (
    <div>
      <div className='container'>
        <h1 style={{ color: "red", textAlign: "center", marginTop: "20px", fontWeight: "bold" }}>Softwares</h1>
        <div className='booksContainer' style={{ border: "1px solid", margin: "90px", padding: "40px", marginTop: "20px" }}>
          {softwares.map((software) => {
            return <div key={software.id} className='singleBook' style={{ border: "1px solid", padding: "15px", margin: "10px" }}>
              <div className='row'>
                <div className='col-lg-10'>
                  <p>{software.title}</p>
                </div>
                <div className='col-lg-2'>
                  <a href={`${software.softlink}`} target="_blank">
                    Open &nbsp;
                    <i
                      className="fa-solid fa-arrow-up-right-from-square"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  )
}

export default Softwares
