import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import '../css/seminar.css';
import ScholarshipItem from './itemComponents/ScholarshipItem';
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const StudentScholarship = () => {
    const alert = useAlert();
    const [scholarships, setScholarships] = useState([]);
    const [courses, setCourses] = useState([]);

    const [showCourseSelect, setShowCourseSelect] = useState(false);

    const [applySchlrshipDetails, setApplySchlrshipDetails] = useState({
        name: "",
        email: "",
        mobile: "",
        schlrship: "",
        note: "",
        course: "",
    })

    const fetchScholarships = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/scholarships/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setScholarships(json.result);
    }

    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetchnames",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    const applySchlrshipSubmit = async (e) => {
        const { name, email, mobile, schlrship, note, course } = applySchlrshipDetails;
        e.preventDefault()

        const response = await fetch(`https://api.caddcornerbd.com/api/scholarships/applyschlrship`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                mobile,
                schlrship,
                note,
                course
            }),
        });

        const json = await response.json();
        if (json.success) {
            setApplySchlrshipDetails({
                name: "",
                email: "",
                mobile: "",
                schlrship: "",
                note: "",
                course: "",
            });
            alert.success("Successfully applied for the scholarship");
            setShowCourseSelect(false);
            socket.emit("apply_schlrship", {
                message: "schlrship applied"
            })
        }
    }

    const scrollToScholarships = () => {
        const element = document.getElementById('scholarshipBox');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleOnChangeSelectSchlrship = (e) => {
        if (e.target.value !== '') {
            setShowCourseSelect(true);
        } else {
            setShowCourseSelect(false);
        }
    }
    //on change input
    const onChangeInput = (e) => {
        setApplySchlrshipDetails({ ...applySchlrshipDetails, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchScholarships();
        fetchCourses();
        socket.on("added_schlrship", (data) => {
            fetchScholarships();
        });
        socket.on("deleted_schlrship", (data) => {
            fetchScholarships();
        });
        socket.on("updated_schlrship", (data) => {
            fetchScholarships();
        });
        //courses socket
        socket.on("added_course", (data) => {
            fetchCourses();
        });
        socket.on("deleted_course", (data) => {
            fetchCourses();
        });
        socket.on("updated_course", (data) => {
            fetchCourses();
        });
    }, [])

    return (
        <div>
            <div className="container-fluid seminarFirstFluid">
                <div className="container seminarFirstCon">
                    <h1 style={{ fontWeight: "600" }}>
                        SCHOLARSHIPS
                    </h1>
                </div>
            </div>
            <div className="container-fluid seminarSecFluid">
                <div className="container seminarSecCon">
                    <div className="row">
                        <div className='col-12 d-lg-none'>
                            <div className='scrollBox' style={{
                                backgroundColor: "#d72323", color: "#fff", textAlign: "center",
                                padding: "10px", margin: "15px 0"
                            }}>
                                {scholarships.length === 0 ? (
                                    <h4>No Scholarships Available Now</h4>
                                ) : (
                                    <p onClick={scrollToScholarships}>{scholarships.length=== 1?(`${scholarships.length} scholarship`):(`${scholarships.length} scholarships`)} available!<br/>Checkout them...</p>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="column4" style={{ marginTop: "0px" }}>
                                <h2>
                                    Apply For Scholarship
                                </h2>
                                <small>সর্বোচ্চ ০১ কর্ম দিবসের মধ্যে আপনার সাথে যোগাযোগ করা হবে।</small> <br />
                                <br />
                                <small id="errorShow" style={{ color: "red" }}></small>
                                <form onSubmit={applySchlrshipSubmit}>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Name" name="name" autoComplete="off" required onChange={onChangeInput} value={applySchlrshipDetails.name} />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email address" name="email" autoComplete="off" required onChange={onChangeInput} value={applySchlrshipDetails.email} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Mobile no." name="mobile" autoComplete="off" id="mobileNo" onChange={onChangeInput} required value={applySchlrshipDetails.mobile} />
                                    </div>
                                    <div className="form-group">
                                        <select name="schlrship" className="form-select" id="selSchlr" autoComplete="off" required onChange={(e) => { onChangeInput(e); handleOnChangeSelectSchlrship(e) }} value={applySchlrshipDetails.schlrship}>
                                            <option value="">Select Scholarship</option>
                                            {scholarships.map((scholarship) => {
                                                return <option key={scholarship.id}>{scholarship.schlrship}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group" id="note" style={{ display: "none" }}>
                                        <select name="note" className="form-control" id="note" autoComplete="off" onChange={onChangeInput} value={applySchlrshipDetails.note}>
                                            <option value="">Please select</option>
                                            <option>নন কোটা</option>
                                            <option>মুক্তিযোদ্ধা কোটা</option>
                                            <option>প্রতিবন্ধি কোটা</option>
                                        </select>
                                    </div>
                                    <div className={`form-group ${showCourseSelect ? ('') : ('d-none')}`} id="courseSel2">
                                        <select name="course" className="form-select" id="course" autoComplete="off" required onChange={(e) => { onChangeInput(e); }} value={applySchlrshipDetails.course}>
                                            <option value="">Select Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn" id="subBtn">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8" id='scholarshipBox'>
                            <div className="column8">
                                <div className="seminarInCon">
                                    <h2 className="h2Class" style={{ color: "red", fontWeight: "600" }}>CURRENT SCHOLARSHIPS : {scholarships.length}</h2><hr />
                                    {scholarships.map((scholarship, el) => {
                                        return <ScholarshipItem key={scholarship.id} sl={(el++) + 1} scholarship={scholarship} />
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentScholarship
