import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import '../css/seminar.css';
import OfferItem from './itemComponents/OfferItem';
import { useAlert } from "react-alert";

const socket = io.connect("https://api.caddcornerbd.com");

const ApplyForAdmission = () => {
    const alert = useAlert();
    const [offers, setOffers] = useState([]);
    const [courses, setCourses] = useState([]);

    const [applySchlrshipDetails, setApplySchlrshipDetails] = useState({
        name: "",
        email: "",
        mobile: "",
        coursename: ""
    })

    const fetchOffers = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/offers/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setOffers(json.result);
    }

    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetchnames",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    const applyForAdmission = async (e) => {
        const { name, email, mobile, coursename } = applySchlrshipDetails;
        e.preventDefault()

        const response = await fetch(`https://api.caddcornerbd.com/api/applications/apply`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                mobile,
                coursename
            }),
        });

        const json = await response.json();
        if (json.success) {
            setApplySchlrshipDetails({
                name: "",
                email: "",
                mobile: "",
                coursename: "",
            });
            alert.success("Successfully applied!");
            socket.emit("apply_foradmission", {
                message: "applied for admission"
            })
        }
    }

    //on change input
    const onChangeInput = (e) => {
        setApplySchlrshipDetails({ ...applySchlrshipDetails, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchOffers();
        fetchCourses();
        socket.on("added_offer", (data) => {
            fetchOffers();
        });
        socket.on("deleted_offer", (data) => {
            fetchOffers();
        });
        socket.on("updated_offer", (data) => {
            fetchOffers();
        });
        //courses socket
        socket.on("added_course", (data) => {
            fetchCourses();
        });
        socket.on("deleted_course", (data) => {
            fetchCourses();
        });
        socket.on("updated_course", (data) => {
            fetchCourses();
        });
    }, [])

    return (
        <div>
            <div className="container-fluid seminarSecFluid">
                <div className="container seminarSecCon">
                    <div className="row">
                        <div className="col-lg-4">
                            <div class="ins_jumbo">
                                <div class="instructions">
                                    <p>
                                        আমাদের কোর্স নিতে নিচের ফরমটি পূরণ করে সাবমিট করুন। সর্বোচ্চ ০১ কর্ম দিবসের মধ্যে আপনার সাথে যোগাযোগ করা হবে। তাছাড়া কোনো সমস্যার সম্মুখীন হলে 01841-319627 এই নাম্বারে যোগাযোগ করতে পারেন।
                                    </p>
                                </div>
                            </div>
                            <div className="column4">
                                <h2>
                                    Apply For Admission
                                </h2>
                                <small>সর্বোচ্চ ০১ কর্ম দিবসের মধ্যে আপনার সাথে যোগাযোগ করা হবে।</small> <br />
                                <br />
                                <small id="errorShow" style={{ color: "red" }}></small>
                                <form onSubmit={applyForAdmission}>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Name" name="name" autoComplete="off" required onChange={onChangeInput} value={applySchlrshipDetails.name} />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email address" name="email" autoComplete="off" required onChange={onChangeInput} value={applySchlrshipDetails.email} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Mobile no." name="mobile" autoComplete="off" id="mobileNo" onChange={onChangeInput} required value={applySchlrshipDetails.mobile} />
                                    </div>
                                    <div className="form-group" id="courseSel2">
                                        <select name="coursename" className="form-select" id="coursename" autoComplete="off" required onChange={(e) => { onChangeInput(e); }} value={applySchlrshipDetails.coursename}>
                                            <option value="">Select Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn" id="subBtn">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="column8">
                                <div className="seminarInCon">
                                    <h2 className="h2Class" style={{ color: "red", fontWeight: "600" }}>CURRENT OFFERS : {offers.length}</h2><hr />
                                    {offers.map((offer, el) => {
                                        return <OfferItem key={offer.id} sl={(el++) + 1} offer={offer} />
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplyForAdmission
