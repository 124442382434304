import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

const StudentLogin = () => {
    const alert = useAlert();
    let navigate = useNavigate();

    // states
    const [studentLoginDetails, setStudentLoginDetails] = useState({ email: "", password: "" });

    const [loginComp, setLoginComp] = useState(true);
    const [enterEmailComp, setEnterEmailComp] = useState(false);
    const [enterVerCodeComp, setEnterVerCodeComp] = useState(false);
    const [newPassComp, setNewPassComp] = useState(false);

    const [enteredEmail, setEnteredEmail] = useState("");
    const [eneteredCode, setEnteredCode] = useState("");
    const [codeFromServer, setCodeFromServer] = useState("");
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");

    const [emailVerifyLoading, setEmailVerifyLoading] = useState(false);
    const [codeVerifyLoading, setCodeVerifyLoading] = useState(false);
    const [newPassLoading, setNewPassLoading] = useState(false);
    //states

    //handle student login
    const handleStudentLogin = async (e) => {
        e.preventDefault();
        const { email, password } = studentLoginDetails;

        const response = await fetch(`https://api.caddcornerbd.com/api/auth/studentlogin`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                password
            }),
        });

        const json = await response.json();
        if (json.success) {
            setStudentLoginDetails({
                email: "", password: ""
            });
            localStorage.setItem("authToken", json.authToken);
            navigate("/resources");
        }
        if (json.error) {
            alert.error(json.errorMsg);
        }
    }

    const handleCodeVerify = async (e) => {
        e.preventDefault();
        setCodeVerifyLoading(true);
        // eslint-disable-next-line eqeqeq
        if (eneteredCode == codeFromServer) {
            setCodeVerifyLoading(false);
            setEnterVerCodeComp(false);
            setEnteredCode("");
            setCodeFromServer("");
            setNewPassComp(true);
        } else {
            setCodeVerifyLoading(false);
            alert.error('please enter the code correctly');
        }
    }

    const handleSetNewPass = async (e) => {
        e.preventDefault();
        setNewPassLoading(true);
        // eslint-disable-next-line eqeqeq
        if (newPass === confirmNewPass) {
            const response = await fetch(`https://api.caddcornerbd.com/api/auth/setnewpass`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: enteredEmail,
                    newpass: newPass
                }),
            });

            const json = await response.json();
            if (json.success) {
                setNewPassLoading(false);
                setEnteredEmail("");
                setNewPassComp(false);
                setLoginComp(true);
                alert.success("Your password changed successfully")
            }
        } else {
            setNewPassLoading(false);
            alert.error("Please enter the same password twice")
        }
    }

    const handleEmailVerify = async (e) => {
        e.preventDefault();
        setEmailVerifyLoading(true);
        const response = await fetch(`https://api.caddcornerbd.com/api/auth/emailverify`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: enteredEmail
            }),
        });

        const json = await response.json();
        if (json.success) {
            setEmailVerifyLoading(false);
            setEnterEmailComp(false);
            setEnterVerCodeComp(true);
            setCodeFromServer(json.code);
        }
        if (json.error) {
            setEmailVerifyLoading(false);
            alert.error(json.errorMsg);
        }
    }

    const onEnterEmail = (e) => {
        setEnteredEmail(e.target.value);
    }

    const onEnterCode = (e) => {
        setEnteredCode(e.target.value);
    }

    const onEnterNewPass = (e) => {
        setNewPass(e.target.value);
    }

    const onEnterConfirmNewPass = (e) => {
        setConfirmNewPass(e.target.value);
    }

    // on change input
    const onChangeInput = (e) => {
        setStudentLoginDetails({ ...studentLoginDetails, [e.target.name]: e.target.value });
    }
    return (
        <div>
            <div className="container-fluid">
                <div className="container">
                    <div className='row'>
                        <div className="jumbotron main_jumbo my-5 mx-auto" style={{ maxWidth: "900px", padding: "100px 200px" }}>
                            {loginComp && <div className='loginComponent'>
                                <h1 style={{ textAlign: "center", color: "red" }}>Student Login</h1>
                                <br />
                                <form onSubmit={handleStudentLogin} className="d-block m-0 col-sm" >
                                    <input type="email" name="email" id="email" value={studentLoginDetails.email} placeholder="Enter you email" onChange={onChangeInput} className="form-control col-6 mx-auto"
                                        autoComplete="off" required />
                                    <br />
                                    <input type="password" name="password" id="password" value={studentLoginDetails.password} placeholder="Enter your password" onChange={onChangeInput}
                                        className="form-control col-6 mx-auto" autoComplete="off" required />
                                    <br />
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-success mx-2 ">Login</button>
                                        <button type='button' onClick={() => { setLoginComp(false); setEnterEmailComp(true) }}>forgot password</button>
                                    </div>
                                </form>
                            </div>}
                            {enterEmailComp && <div className='enterEmailComponent'>
                                <h3 style={{ textAlign: "center", color: "red" }}>Please enter your registered email</h3>
                                <br />
                                <form onSubmit={handleEmailVerify} className="d-block m-0 col-sm" >
                                    <input type="email" name="email" id="email" placeholder="Enter you email" onChange={onEnterEmail} className="form-control col-6 mx-auto"
                                        autoComplete="off" required />
                                    <br />
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-success mx-2 ">
                                            {emailVerifyLoading ? ('Please wait...') : ('Submit')}
                                        </button>
                                        <button type='button' onClick={() => { setLoginComp(true); setEnterEmailComp(false) }}>Cancel</button>
                                    </div>
                                </form>
                            </div>}
                            {enterVerCodeComp && <div className='enterEmailComponent'>
                                <h3 style={{ textAlign: "center", color: "red" }}>Please check your mailbox for the verification code</h3>
                                <br />
                                <form onSubmit={handleCodeVerify} className="d-block m-0 col-sm" >
                                    <input type="text" name="code" id="code" placeholder="Verification code" onChange={onEnterCode} className="form-control col-6 mx-auto"
                                        autoComplete="off" required />
                                    <br />
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-success mx-2 ">
                                            {codeVerifyLoading ? ('Please wait...') : ('Proceed')}
                                        </button>
                                        <button type='button' onClick={() => { setLoginComp(true); setEnterVerCodeComp(false) }}>Cancel</button>
                                    </div>
                                </form>
                            </div>}
                            {newPassComp && <div className='newPassComp'>
                                <h3 style={{ textAlign: "center", color: "red" }}>Enter your new password.</h3>
                                <br />
                                <form onSubmit={handleSetNewPass} className="d-block m-0 col-sm" >
                                    <input type="text" name="code" id="code" placeholder="New Password" onChange={onEnterNewPass} className="form-control col-6 mx-auto"
                                        autoComplete="off" required />
                                    <br />
                                    <input type="text" name="code" id="code" placeholder="Confrim New Password" onChange={onEnterConfirmNewPass} className="form-control col-6 mx-auto"
                                        autoComplete="off" required />
                                    <br />
                                    <div className="d-flex justify-content-center">
                                        <button type="submit" className="btn btn-success mx-2 ">
                                            {newPassLoading ? ('Please wait...') : ('Confirm')}
                                        </button>
                                        <button type='button' onClick={() => { setLoginComp(true); setNewPassComp(false) }}>Cancel</button>
                                    </div>
                                </form>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentLogin
