import React, { useEffect, useState } from 'react';
import io from "socket.io-client";
import '../css/seminar.css';
import { useAlert } from "react-alert";
import SeminarItem from './itemComponents/SeminarItem';

const socket = io.connect("https://api.caddcornerbd.com");


const Seminars = () => {
    const alert = useAlert();
    const [seminars, setSeminars] = useState([]);
    const [courses, setCourses] = useState([]);

    const [applySeminarDetails, setApplySeminarDetails] = useState({
        name: "",
        email: "",
        mobile: "",
        institue: "",
        fieldstudy: "",
        coursename: ""
    })

    const fetchSeminars = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/seminars/fetch",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setSeminars(json.result);
    }

    const fetchCourses = async () => {
        const response = await fetch(
            "https://api.caddcornerbd.com/api/courses/fetchnames",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const json = await response.json();
        setCourses(json.result);
    }

    const applyForSeminar = async (e) => {
        const { name, email, mobile, institute, fieldstudy, coursename } = applySeminarDetails;
        e.preventDefault()

        const response = await fetch(`https://api.caddcornerbd.com/api/seminars/apply`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name, email, mobile, institute, fieldstudy, coursename
            }),
        });

        const json = await response.json();
        if (json.success) {
            setApplySeminarDetails({
                name: "",
                email: "",
                mobile: "",
                institue: "",
                fieldstudy: "",
                coursename: ""
            });
            alert.success("Successfully applied!");
            socket.emit("apply_forseminar", {
                message: "applied for seminar"
            })
        }
    }

    const scrollToSeminars = () => {
        const element = document.getElementById('seminarBox');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    //on change input
    const onChangeInput = (e) => {
        setApplySeminarDetails({ ...applySeminarDetails, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        fetchSeminars();
        fetchCourses();
        socket.on("added_seminar", (data) => {
            fetchSeminars();
        });
        socket.on("deleted_seminar", (data) => {
            fetchSeminars();
        });
        socket.on("updated_seminar", (data) => {
            fetchSeminars();
        });
        //courses socket
        socket.on("added_course", (data) => {
            fetchCourses();
        });
        socket.on("deleted_course", (data) => {
            fetchCourses();
        });
        socket.on("updated_course", (data) => {
            fetchCourses();
        });
    }, [])

    return (
        <div>
            <div className="container-fluid seminarFirstFluid">
                <div className="container seminarFirstCon">
                    <h1 style={{ fontWeight: "600" }}>
                        SEMINARS
                    </h1>
                </div>
            </div>
            <div className="container-fluid seminarSecFluid">
                <div className="container seminarSecCon">
                    <div className="row">
                        <div className='col-12 d-lg-none'>
                            <div className='scrollBox' style={{
                                backgroundColor: "#d72323", color: "#fff", textAlign: "center",
                                padding: "10px", margin: "15px 0"
                            }}>
                                {seminars.length === 0 ? (
                                    <h4>No Seminars Available Now</h4>
                                ) : (
                                    <p onClick={scrollToSeminars}>{seminars.length === 1 ? (`${seminars.length} seminar`) : (`${seminars.length} seminars`)} available!<br />Checkout them...</p>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="column4">
                                <h2 style={{ fontFamily: "'Bangla', sans-serif" }}>
                                    ক্যাড কর্ণার ফ্রিল্যানসিং বিষয়ক ফ্রি সেমিনার
                                </h2>
                                <small>আপনি সিলেক্টেড হলে কল করে জানানো হবে।</small> <br />
                                <br />
                                <small id="errorShow" style={{ color: "red" }}></small>
                                <form onSubmit={applyForSeminar}>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Name" name="name" autoComplete="off" required onChange={onChangeInput} value={applySeminarDetails.name} />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" placeholder="Email address" name="email" autoComplete="off" required onChange={onChangeInput} value={applySeminarDetails.email} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Mobile no." name="mobile" autoComplete="off" id="mobileNo" onChange={onChangeInput} required value={applySeminarDetails.mobile} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Institute" name="institute" autoComplete="off" id="institute" onChange={onChangeInput} required value={applySeminarDetails.institute} />
                                    </div>
                                    <div className='form-group'>
                                        <select name='fieldstudy' required className='form-select' id='' autoComplete='off' onChange={(e) => { onChangeInput(e) }} value={applySeminarDetails.fieldstudy}>
                                            <option value=''>Select Field of Study</option>
                                            <option>Civil</option>
                                            <option>Architectural</option>
                                            <option>Electrical</option>
                                            <option>Mechanical</option>
                                            <option>Others</option>
                                        </select>
                                    </div>
                                    <div className="form-group" id="courseSel2">
                                        <select name="coursename" className="form-select" id="coursename" autoComplete="off" required onChange={(e) => { onChangeInput(e); }} value={applySeminarDetails.coursename}>
                                            <option value="">Select Course</option>
                                            {courses.map((course) => {
                                                return <option key={course.id}>{course.name} ({course.catagory})</option>
                                            })}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn" id="subBtn">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8" id='seminarBox'>
                            <div className="column8">
                                <div className="seminarInCon">
                                    <h2 className="h2Class" style={{ color: "red", fontWeight: "600" }}>UPCOMING SEMINARS : {seminars.length}</h2><hr />
                                    {seminars.map((seminar, el) => {
                                        return <SeminarItem key={seminar.id} sl={(el++) + 1} seminar={seminar} />
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Seminars
