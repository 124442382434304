/* eslint-disable array-callback-return */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const Books = () => {
  let navigate = useNavigate();


  const [validity, setValidity] = useState(false);
  const [studentInfo, setStudentInfo] = useState({});
  const [books, setBooks] = useState([]);

  //fetch student information
  const fetchStudentInfo = async () => {
    let token = localStorage.getItem("authToken");
    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/fetchaa/${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setStudentInfo(json.result[0]);
    if (json.validity) {
      setValidity(true);
    }
  }

  //fetch books
  const fetchBooks = async () => {
    const response = await fetch(
      `https://api.caddcornerbd.com/api/students/fetchbooks`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    setBooks(json.result);
    if (json.validity) {
      setValidity(true);
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/student/login");
    }
    fetchStudentInfo();
    fetchBooks();
  })
  return (
    <div>
      {validity && <div className='container'>
        <h1 style={{color:"red", textAlign:"center", marginTop:"20px", fontWeight:"bold"}}>Books</h1>
        <div className='booksContainer' style={{ border: "1px solid", margin: "90px", padding: "40px", marginTop:"20px" }}>
          {books.map((book) => {
            if (book.coursename === studentInfo.coursename) {
              return <div key={book.id} className='singleBook' style={{ border: "1px solid", padding: "15px", margin:"10px" }}>
                <div className='row'>
                  <div className='col-lg-10'>
                    <p>{book.title}</p>
                  </div>
                  <div className='col-lg-2'>
                    <a href={`https://api.caddcornerbd.com/schlrships/books/${book.booklink}`} download>
                      Download &nbsp;
                      <i
                        className="fa-solid fa-download"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            }
          })}

        </div>

      </div>}
      
    </div>
  )
}

export default Books
