import React from 'react';
import '../css/firstNav.css';

const FirstNav = () => {
    return (
        <div className='firstNav'>
            <div className='container d-none d-lg-block'>
                <div className='row'>
                    <div className='col-lg-3'>
                        <p><i className="fa-solid fa-phone"></i> 01919-319627 | 01841-319627</p>
                    </div>
                    <div className='col-lg-6'>
                        {/* eslint-disable-next-line */}
                        <marquee>
                            Welcome To CADD CORNER
                        </marquee>
                    </div>
                    <div className='col-lg-3'>
                        <a href="mailto:caddcornerbd@gmail.com" className="text onHoverNoTDec"><i className="fas fa-envelope"></i> caddcornerbd@gmail.com</a>
                    </div>
                </div>
            </div>
            <div className='container-fluid d-lg-none'>
                <div className='row' style={{padding:"15px 0"}}>
                    <div className='col-3' style={{textAlign:"center"}}>
                        <a style={{color:"#fff"}} href='https://www.facebook.com/caddcornerbd' target="_blank" rel="noreferrer"><i class="fa-brands fa-facebook"></i></a>
                    </div>
                    <div className='col-6'>
                        {/* eslint-disable-next-line */}
                        <marquee>
                            Welcome To CADD CORNER
                        </marquee>
                    </div>
                    <div className='col-3' style={{textAlign:"center"}}>
                        <a href="mailto:caddcornerbd@gmail.com" className="text onHoverNoTDec"><i className="fas fa-envelope"></i> </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirstNav
