import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClassVideos from "./ClassVideos";

const ClassVideosContainer = () => {
    let navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/student/login");
        }
        // eslint-disable-next-line
    }, []);
    return (
        <div>
            {localStorage.getItem("authToken") && <ClassVideos />}
        </div>
    )
}

export default ClassVideosContainer
